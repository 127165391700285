import { getCookie } from './utils/cookies/getCookie';
import { setCookie } from './utils/cookies/setCookie';
import { generateUniqueIdentifier } from './utils/uniqueIdentifier';
import { setupDataLayerWithCallback } from './utils/dataLayer';

export const PERSEUS_COOKIES = {
  HIT_MATCH: {
    KEY: 'dhhPerseusHitId',
    path: '/',
    EXPIRES: 60 * 60 * 1000 //one hour expiration time
  }, 
  GUEST_ID: {
    KEY: 'dhhPerseusGuestId',
    path: '/',
    EXPIRES: 604800000, //one week expiration time
  },
  SESSION_ID: {
    KEY: 'dhhPerseusSessionId',
    path: '/',
    EXPIRES: 30 * 60 * 1000 //half hour expiration time
  }
}

export function getPerseusCookie(key, defaultValue) {
  const cookieValue = getCookie(key, {}); 
  const perseusCookie = cookieValue || defaultValue;
  return perseusCookie;
}

export function updatePerseusCookie(cookieKey, { expires, path= '/' } = {}, defaultValue){
  const perseusCookie = getPerseusCookie(cookieKey, defaultValue);
  setCookie(cookieKey, perseusCookie, { expires, path }); 
  return perseusCookie;
}

export function setPerseusHitMatchCookie() {
  const perseusCookie = generateUniqueIdentifier();
  setCookie(PERSEUS_COOKIES.HIT_MATCH.KEY, perseusCookie, { expires: PERSEUS_COOKIES.HIT_MATCH.EXPIRES, path: '/' });
  return perseusCookie;
}

export function setPerseusSessionCookie(){
  return updatePerseusCookie(PERSEUS_COOKIES.SESSION_ID.KEY, {expires: PERSEUS_COOKIES.SESSION_ID.EXPIRES} , generateUniqueIdentifier()); 
}

export function setPerseusGuestIdCookie(){
  return updatePerseusCookie(PERSEUS_COOKIES.GUEST_ID.KEY, { expires: PERSEUS_COOKIES.GUEST_ID.EXPIRES }, generateUniqueIdentifier()); 
}

export function setPerseusCookies(){
  setPerseusHitMatchCookie();
  setPerseusSessionCookie();
  setPerseusGuestIdCookie();
}

export function getPerseusHitMatchCookie(){
  return getPerseusCookie(PERSEUS_COOKIES.HIT_MATCH.KEY, generateUniqueIdentifier()); 
}

export function getPerseusSessionCookie(){
  return getPerseusCookie(PERSEUS_COOKIES.SESSION_ID.KEY, generateUniqueIdentifier()); 
}

export function getPerseusGuestIdCookie(){
  return getPerseusCookie(PERSEUS_COOKIES.GUEST_ID.KEY, generateUniqueIdentifier()); 
}

export function setPerseusTrackingCookies(){
  setupDataLayerWithCallback(setPerseusCookies);

}