export function setupDataLayerWithCallback(cbFunction) {
    try{
        if (typeof window !== 'undefined' && window.dataLayer) {
            const original = window.dataLayer.push;
            window.dataLayer.push = function (eventObj) {
                cbFunction && cbFunction();
                return original.apply(window.dataLayer, [eventObj]);
            }
        }
    } catch(error){
        console.error(error);
        return null;
    }
}