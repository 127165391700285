export function setCookie(key, value, options = {}, responseCookies = () => {}) {
  if (!key || !value) {
    throw new Error('Both key and value must be provided for the cookie.');
  }

  let cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

  if (options.expires instanceof Date) {
    cookie += `; expires=${options.expires.toUTCString()}`;
  } else if (options.expires) {
    const expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() + options.expires);
    cookie += `; expires=${expirationTime.toUTCString()}`;
  }

  if (options.path) {
    cookie += `; path=${options.path}`;
  }

  if (options.domain) {
    cookie += `; domain=${options.domain}`;
  }

  if (options.httpOnly) {
    cookie += `; httpOnly`;
  }

  if (options.secure) {
    cookie += `; secure`;
  }

  if (options.sameSite) {
    cookie += `; sameSite=${options.sameSite}`;
  }

  if (typeof window !== 'undefined') {
    document.cookie = cookie;
  } else {
    responseCookies && typeof responseCookies.cookie === 'function' && responseCookies.cookie(key, value, {
      path: options.path || '/',
      httpOnly: options.httpOnly || false,
      secure: options.secure || false,
      sameSite: options.sameSite || 'lax',
      maxAge: options.maxAge || options.expires,
    });
  }
}
